<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Terms & Conditions</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Terms & Conditions</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="privecy-area ptb-100">
    <div class="container">
        <h2>Welcome to Godavari Wave Technologies Terms & Conditions</h2>
        <p>By accessing or using the services provided by Godavari Wave Technologies, you agree to be bound by our terms
            and conditions. Our services, which include [briefly describe services, e.g., software development, IT
            consulting, etc.], are available to users who comply with these terms. When you register for an account, you
            agree to provide accurate and up-to-date information and to protect your account’s security. Some services
            may require payment, and by using those services, you agree to the applicable fees. All content and
            intellectual property related to our services remain the property of Godavari Wave Technologies, and you are
            prohibited from using, copying, or distributing any materials without permission. </p>
        <p>We value your privacy and protect your personal data as outlined in our Privacy Policy. We reserve the right
            to suspend or terminate your access to our services for violations of these terms, and our liability is
            limited to the fees you paid for the services. These terms are governed by the laws of [your jurisdiction],
            and we may update these terms periodically. Please review them regularly, and if you have any questions,
            feel free to contact us at [contact information].
        </p>
        <h2> Godavari Wave Technologies Terms & Conditions</h2>
        <p>Godavari Wave Technologies is a leading provider of innovative solutions in the technology industry.
            Established in [year], Godavari Wave Technologies has been at the forefront of delivering cutting-edge
            services that help businesses streamline their operations and stay competitive in an ever-evolving digital
            landscape. Our team of skilled professionals specializes in areas such as [insert services like software
            development, IT consulting, etc.], ensuring that our clients receive the highest quality solutions.
            Since our inception, Godavari Wave Technologies has been dedicated to creating impactful and scalable
            products for our clients. We have built a strong reputation for delivering results that exceed expectations,
            and our commitment to innovation drives us to continuously improve and adapt to the latest technological
            advancements. Whether through partnerships or custom solutions, Godavari Wave Technologies continues to lead
            the way in providing exceptional service to our clients globally.
        </p>
        <p> Our mission is to empower businesses to achieve success through technology. With years of expertise, a
            passion for progress, and a focus on customer satisfaction, Godavari Wave Technologies remains a trusted
            name in the industry.</p>
    </div>
</section>