<footer class="footer-area " style="padding-top: 50px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <div class="logo">
                        <div style="background-color: white ;border-radius: 5px;text-align: center;padding: 2% 0%;">
                            <a><img src="assets/images/Logo.svg" alt="logo" /></a>
                        </div>
                    </div>
                    <p>Godavari Wave delivers comprehensive tech solutions tailored to meet diverse business needs. From
                        software development to IT consulting, we ensure innovation and excellence .</p>

                    <!-- <div class="subscribe">
                        <h4>Join Newsletter</h4>
                        <form class="newsletter-form">
                            <input type="email" id="emails" class="form-control" placeholder="Your Email" name="EMAIL"
                                required autocomplete="off">
                            <button class="box-btn" type="submit">Subscribe</button>
                        </form>
                    </div> -->

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content ml-15">
                    <h3>Our Service</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/">UX/UI Design</a></li>
                        <li><a routerLink="/"> Development</a></li>
                        <li><a routerLink="/">QA & Testing</a></li>
                        <li><a routerLink="/">IT Management</a></li>
                        <li><a routerLink="/">Cyber Security</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="content">
                    <h3>Quick Links</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/faq">FAQ</a></li>
                        <li><a routerLink="/solutions">Service</a></li>
                        <!-- <li><a routerLink="/">Career</a></li/> -->
                        <li><a routerLink="/terms-condition">Privacy & Policy</a></li>
                        <li><a routerLink="/terms-condition">Terms & Conditions</a></li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">Contact</h3>

                    <ul class="footer-list foot-social">
                        <li><a href="tel:+917799822811"><i class="bx bx-mobile-alt"></i> +91 7799822811</a></li>
                        <li><a href="tel:+917799822799"><i class="bx bx-phone"></i> +91 7799822799</a></li>
                        <li><a href="mailto:info@godavariwave.com"><i
                                    class="bx bxs-envelope"></i>info&#64;godavariwave.com</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copy-area" style="margin-top: 20px;">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <ul class="menu">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about">About</a></li>
                        <li><a routerLink="/solutions">Solutions</a></li>
                        <li><a routerLink="/case">Case Studies</a></li>
                        <li><a routerLink="/blog">Blog</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>

                <div class="col-lg-6">
                    <p>©2024 Godavari Wave is Proudly Owned by <a href="" target="_blank">Godavari
                            Wave</a></p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>