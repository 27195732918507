<section class="slider-area-2">
    <div class="home-slider">
        <owl-carousel-o [options]="homeSlides">
            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-1">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>Godavari Wave Technologies PVT LTD</h1>
                                            <p>Godavari Wave Technologies Pvt. Ltd. is a leading innovator in digital
                                                solutions, specializing in software development and IT services. With a
                                                commitment to excellence, they deliver cutting-edge technology to
                                                empower businesses and drive success.</p>
                                            <div class="slider-btn text-center">
                                                <a routerLink="/contact" class="box-btn">Let’s Talk!</a>
                                                <a routerLink="/about" class="box-btn border-btn">Know More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template> <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-2">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>Digital Agency & Marketing</h1>
                                            <p> Godavari Wave Technologies Pvt. Ltd. offers customized software
                                                solutions tailored to meet diverse industry needs, ensuring efficiency
                                                and scalability. Their expert team combines innovation and technology to
                                                deliver results that transform business operations.</p>
                                            <div class="slider-btn text-center">
                                                <a routerLink="/contact" class="box-btn">Let’s Talk!</a>
                                                <a routerLink="/about" class="box-btn border-btn">Know More!</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-3">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>Make Real-Life Connections With IT</h1>
                                            <p>At Godavari Wave Technologies Pvt. Ltd., we prioritize customer
                                                satisfaction by providing comprehensive IT consulting and support
                                                services. Our mission is to harness the power of technology to help
                                                businesses thrive in an increasingly competitive landscape.</p>
                                            <div class="slider-btn text-center">
                                                <a routerLink="/contact" class="box-btn">Let’s Talk!</a>
                                                <a routerLink="/about" class="box-btn border-btn">Know More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</section>

<section class="home-service-area pb-70 pt-100 ">
    <div class="container">
        <div class="section-title">
            <span>Smart Services</span>
            <h2>Godavari Wave Provide All Kind of Tech Solutions</h2>
            <p> Godavari Wave delivers comprehensive tech solutions tailored to meet diverse business needs. From
                software development to IT consulting, we ensure innovation and excellence.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Visual Design</h3>
                        <p>Creating visually captivating designs that elevate user experiences and brand identity.</p>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Development</h3>
                        <p>Building robust and scalable solutions that drive digital transformation and business
                            success.</p>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>QA Testing</h3>
                        <p>
                            Ensuring quality and performance through meticulous testing and validation for flawless
                            software delivery.</p>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>IT Management</h3>
                        <p>Streamlining IT operations and aligning technology with business goals for enhanced
                            efficiency and growth.</p>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> Cyber Security</h3>
                        <p>
                            Protecting your digital assets with comprehensive security solutions that safeguard against
                            evolving threats.</p>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> Wireless Connectivity</h3>
                        <p> Enabling seamless and reliable wireless connectivity for enhanced communication and data
                            transfer.</p>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="home-company-area bg-color">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Our Godavari Wave Tech</span>
                        <h2>Innovative It Helping Service All Over the World</h2>
                        <p>At Godavari Wave, we specialize in delivering a wide array of tech solutions designed to
                            address the unique challenges faced by businesses in today's fast-paced digital landscape.
                            Our expert team leverages the latest technologies to provide services ranging from software
                            development and IT management to cybersecurity and wireless connectivity.</p>
                        <p>Our approach is centered around understanding each client’s specific needs and tailoring our
                            solutions accordingly. Whether it’s enhancing user experience through visual design,
                            ensuring quality through rigorous QA testing, or streamlining operations with effective IT
                            management, Godavari Wave is dedicated to providing comprehensive support every step of the
                            way.</p>
                    </div>

                    <a routerLink="/about" class="box-btn">Know More</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/team/zzz.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-process-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Working Process</span>
            <h2>Quality and innovation drive us.</h2>
            <p>
                Our streamlined working process combines thorough consultation, agile development, and rigorous QA
                testing to deliver tailored tech solutions that meet your unique business needs.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p1.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Research Product</h3>
                        <p>Conducting in-depth research to drive product innovation and meet market demands effectively.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p2.png" alt="process" />
                        <span class="pro-span"><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>User Testing</h3>
                        <p>Gathering user feedback to enhance usability and ensure optimal product performance.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p3.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Development</h3>
                        <p>
                            Creating innovative solutions through efficient coding and collaborative teamwork.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p4.png" alt="process" />
                    </div>

                    <div class="content">
                        <h3>Product Handover</h3>
                        <p> Seamlessly transitioning final products to clients with comprehensive documentation and
                            support.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- <section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Choose Aiva</span>
            <h2>We Achieved People’s Trust by Our Great Service</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Quis ipsum suspendisse.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img.png" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <div class="faq-item" *ngFor="let item of accordionItems; let i = index;">
                            <div class="faq-header" (click)="toggleAccordionItem(item)">
                                {{item.title}}
                                <i class='bx' [ngClass]="{'bx-plus': !item.open, 'bx-x': item.open}"></i>
                            </div>
                            <div class="faq-content" [hidden]="!item.open">
                                <p>{{item.content}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img1.jpg" alt="choose" />

                    <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play'></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Updated Technology</h2>
                    <p>You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem
                        Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the
                        first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined
                        with.</p>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                        alteration in some form, by injected humour, or randomised words which don't look even slightly
                        believable. If you are going There are many variations of passages of Lorem Ipsum available, but
                        the.</p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src="https://www.youtube.com/embed/XNQk1qt__Vw"
            title="Setup and Local Installation - React Nextjs Online Yoga Coaching &amp; Training Courses System"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class='bx bx-x'></i>
        </button>
    </div>
</div>

<section class="home-case ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Case Studies</span>
            <h2>Have a Look Our Work Showcase</h2>
            <p>Explore our work showcase to see innovative solutions that exemplify our expertise and creativity .</p>
        </div>
        <div class="case">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span (click)="switchTab($event, 'tab1')">
                        All
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                    <span (click)="switchTab($event, 'tab2')">
                        Design
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                    <span (click)="switchTab($event, 'tab3')">
                        Development
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                    <span (click)="switchTab($event, 'tab4')">
                        Cyber Security
                    </span>
                </li>
            </ul>
            <div class="tabs-container">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/team/z3.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3> E-Commerce Company Software Development Case</h3>
                                    </a>
                                    <p>We delivered a tailored software solution for an eCommerce company, boosting
                                        conversion rates and customer satisfaction while driving revenue growth.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/team/x1.png"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>Food App UX Studies & Development Case</h3>
                                    </a>
                                    <p> We enhanced a food app through UX studies and development, resulting in a more
                                        intuitive interface and increased user engagement.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/team/z5.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>Restaurant Management & Web Developing</h3>
                                    </a>
                                    <p>
                                        We provide comprehensive restaurant management solutions with tailored web
                                        development to streamline operations and enhance customer experience.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/team/z6.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>IT Software Company Development Case</h3>
                                    </a>
                                    <p>We delivered a customized software solution for an IT company, improving
                                        operational efficiency and facilitating seamless project management.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">

                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/team/x3.png"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>Parking Management & Web Developing</h3>
                                    </a>
                                    <p>We developed a robust parking management system with a user-friendly web
                                        interface to optimize space utilization and enhance user experience .</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">

                                <div class="case-img">
                                    <a routerLink="/case-studies-details">
                                        <img src="assets/images/case/c6.jpg" alt="case" />
                                    </a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>Temperature App UX Studies & Development Case</h3>
                                    </a>
                                    <p>We improved a temperature app through UX studies, designing a user-friendly
                                        interface with real-time monitoring features, enhancing functionality and user
                                        engagement .</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>IT Software Company Development Case</h3>
                                    </a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c5.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>Parking Management & Web Developing</h3>
                                    </a>
                                    <p>
                                        We developed a robust parking management system with a user-friendly web
                                        interface to optimize space utilization and enhance user experience.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c6.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>Temperature App UX Studies & Development Case</h3>
                                    </a>
                                    <p>We conducted UX studies for a temperature app, creating an intuitive design and
                                        developing features for real-time monitoring, leading to enhanced user
                                        interaction and satisfaction.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c2.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>Ride Share App UX Studies & Development Case</h3>
                                    </a>
                                    <p>We performed UX studies for a ride-share app, designing an intuitive interface
                                        and developing features for seamless booking, navigation, and driver-passenger
                                        communication, improving overall user experience.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c3.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>Restaurant Management & Web Developing</h3>
                                    </a>
                                    <p> We created a tailored restaurant management system with a responsive website,
                                        streamlining operations and enhancing the dining experience..</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>IT Software Company Development Case</h3>
                                    </a>
                                    <p>
                                        We developed a scalable software solution for an IT company, improving workflow
                                        efficiency and driving business growth.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c6.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>Temperature App UX Studies & Development Case</h3>
                                    </a>
                                    <p>
                                        We improved a temperature app through UX studies, designing a user-friendly
                                        interface with real-time monitoring features, enhancing functionality and user
                                        engagement.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>IT Software Company Development Case</h3>
                                    </a>
                                    <p>We developed a scalable software solution for an IT company, improving workflow
                                        efficiency and driving business growth.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c5.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>Parking Management & Web Developing</h3>
                                    </a>
                                    <p> We developed a robust parking management system with a user-friendly web
                                        interface to optimize space utilization and enhance user experience.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="case-btn text-center">
            <p>We Have More Amazing Cases. <a routerLink="/">View More</a></p>
        </div>
    </div>
</section>

<section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Testimonials</span>
            <h2>What Our Client’s Say</h2>
            <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque
                blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p> -->
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/1.jpg" alt="img">
                        <p>Their innovative approach completely transformed our business operations, making them more
                            efficient and streamlined. We couldn't be more satisfied with the results.</p>
                        <h3>Kabir Khan</h3>
                        <!-- <span>Jain Merchants</span> -->
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/2.jpg" alt="img">
                        <p>The team delivered an exceptional product, paying close attention to every detail and
                            ensuring a seamless user experience. Their dedication truly exceeded our expectations.</p>
                        <h3>Sharath Rohit</h3>
                        <!-- <span>Company Founder</span> -->
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/2.jpg" alt="img">
                        <p>We saw a significant improvement in performance and efficiency after their tailored solution
                            was implemented. Their professionalism and expertise were evident throughout the project.
                        </p>
                        <h3>Lokesh </h3>
                        <!-- <span>Company Founder</span> -->
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/2.jpg" alt="img">
                        <p>From start to finish, they provided reliable, top-notch service and delivered a product that
                            met all our needs. Their commitment to quality truly set them apart.</p>
                        <h3> Ajay Kumar</h3>
                        <!-- <span>Company Founder</span> -->
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>